function settingsBasedOnCreditsLeft(creditsLeft: number) {
  switch (true) {
    case creditsLeft > 600:
      return {
        background: "var(--GREEN-600)",
        backgroundWithOpacity: "color-mix(in oklab, var(--GREEN-600) 25%, white)"
      };
    case  creditsLeft > 200 && creditsLeft < 600:
      return {
        background: "var(--YELLOW-500)",
        backgroundWithOpacity: "color-mix(in oklab, var(--YELLOW-500) 25%, white)",
        msg: "Running Low on credits"
      };
    case creditsLeft < 200 && creditsLeft > 0:
      return {
        background: "var(--YELLOW-900)",
        backgroundWithOpacity: "color-mix(in oklab, var(--YELLOW-900) 25%, white)",
        msg: "Running very low on credits. Please purchase more credits to ensure uninterrupted progress."
      };
    default:
      return {
        background: "var(--RED-600)",
        backgroundWithOpacity: "color-mix(in oklab, var(--RED-600) 25%, white)",
        msg: "You're out of credits. Please purchase more credits to enable continued work."
      };
  }
}

export {settingsBasedOnCreditsLeft};
