/**
 * Copyright ©2024 Drivepoint
 */

import React, {CSSProperties, forwardRef, ReactNode, useImperativeHandle, useRef, useState} from "react";
import {InfoOutlined} from "@mui/icons-material";
import {Box, ClickAwayListener, Popper} from "@mui/material";
import {PopperProps} from "@mui/material/Popper";
import DPCard from "../DPCard/DPCard";
import "./DPNewTooltip.css";

type DPTooltipProps = {
  triggerEl?: ReactNode;
  children: ReactNode;
  cardCSS?: CSSProperties;
  eventType?: "click" | "hover";
  classes?: string;
} & Pick<PopperProps, "placement">;

export type DPTooltipRef = {
  resetAnchor: () => void;
};

const DPNewTooltip = forwardRef<DPTooltipRef, DPTooltipProps>(
  ({triggerEl, placement, children, cardCSS, eventType = "click", classes = ""}, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const closeTimeout = useRef<number | null>(null);

    useImperativeHandle(ref, () => ({
      resetAnchor
    }));

    function handleAnchorAssignment(event: React.MouseEvent<HTMLElement>): void {
      event.stopPropagation();
      if (closeTimeout.current) clearTimeout(closeTimeout.current); // Prevent premature closing
      setAnchorEl(event.currentTarget);
    }

    function resetAnchor() {
      closeTimeout.current = setTimeout(() => { setAnchorEl(null); }, 200);
    }

    function renderPopper() {
      return (
        <Popper
          onMouseEnter={() => {
            if (closeTimeout.current && eventType === "hover") {
              clearTimeout(closeTimeout.current);
            }
          }}
          onMouseLeave={() => {
            if (eventType === "hover") { resetAnchor(); }
          }}
          className="dp-popper"
          placement={placement}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              options: {element: arrowRef}
            },
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: false,
                rootBoundary: "document",
                padding: 8
              }
            }
          ]}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
        >
          {children && (
            <DPCard className={`dp-popper-body ${classes}`} style={cardCSS}>
              {children}
            </DPCard>
          )}
          <Box component="span" ref={setArrowRef} className={`arrow arrow-${placement}`} />
        </Popper>
      );
    }

    return (
      <>
        <span
          onMouseEnter={(event) => {
            if (eventType === "click") return;
            handleAnchorAssignment(event);
          }}
          onMouseLeave={() => {
            if (eventType === "click") return;
            resetAnchor();
          }}
          onClick={(event) => {
            if (eventType === "hover") return;
            handleAnchorAssignment(event);
          }}
          style={{display: "inline-block", marginRight: "5px", cursor: "pointer"}}
        >
          {triggerEl ?? <InfoOutlined />}
        </span>
        {eventType === "click" && <ClickAwayListener onClickAway={resetAnchor}>{renderPopper()}</ClickAwayListener>}
        {eventType === "hover" && renderPopper()}
      </>
    );
  });

export default DPNewTooltip;
