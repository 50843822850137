/**
 * Copyright ©2023 Drivepoint
 */

import React, {forwardRef, MouseEvent, ReactNode} from "react";
import {Button, ButtonProps} from "@mui/material";
import "./DPButton.css";

export type DPButtonProps = {
  label: ReactNode | "string";
  variant?: "text" | "contained" | "outlined";
  type?: "primary" | "secondary" | "success" | "danger";
  onClick?: (event: MouseEvent) => void;
  submit?: boolean;
} & Omit<ButtonProps, "variant" | "color" | "type" | "onClick">;

const DPButton = forwardRef(({label, variant = "contained", type = "primary", onClick, submit, ...rest}: DPButtonProps, ref: any): any => {
  const classes = [
    "standard-button",
    `standard-button-type-${type}`,
    `standard-button-variant-${variant}`
  ];

  return <Button ref={ref} className={`${classes.join(" ")}`} variant={variant} onClick={onClick} type={submit ? "submit" : undefined} {...rest}>
    {label ?? rest.children}
  </Button>;

});

export default DPButton;
