/**
 * Copyright ©2025 Drivepoint
 */
import React, {useMemo, useRef} from "react";
import {MonetizationOn} from "@mui/icons-material";
import {Badge, Grid, LinearProgress, Link, Skeleton, Typography} from "@mui/material";
import {useModal} from "mui-modal-provider";
import DPAlert from "@components/DPAlert/DPAlert";
import DPButton from "@components/DPButton/DPButton";
import DPNewTooltip, {DPTooltipRef} from "@components/DPNewTooltip/DPNewTooltip";
import DPBuyCreditsDialog from "@components/layout/DPHeader/DPCreditsInfoTooltip/dialogs/DPBuyCreditsDialog/DPBuyCreditsDialog";
import DPRedeemCreditsDialog from "@components/layout/DPHeader/DPCreditsInfoTooltip/dialogs/DPRedeemDialog/DPRedeemDialog";
import {settingsBasedOnCreditsLeft} from "@components/layout/DPHeader/DPCreditsInfoTooltip/logic";
import Numbers from "@utilities/Numbers";
import "./DPCreditsInfoTooltip.css";

type DPCreditsInfoTooltipProps = {
  availableCredits?: number;
  isLoading?: boolean
};

export default function DPCreditsInfoTooltip({availableCredits = 0, isLoading = false}: DPCreditsInfoTooltipProps) {

  const MAX_CREDITS = 1200;

  const {showModal} = useModal();
  const tooltipRef = useRef<DPTooltipRef>(null);
  const creditsLeftInPercent = useMemo(() => {
    return (100 / MAX_CREDITS) * availableCredits;
  }, [availableCredits]);

  function renderTargetElLabel() {
    const visualSettings = settingsBasedOnCreditsLeft(availableCredits);
    return <>
      <Badge className="dp-credits-header-info-badge" sx={{
        "& > .MuiBadge-dot": {
          background: visualSettings.background
        }
      }} variant="dot">
        <MonetizationOn sx={{color: "var(--BLUE-600)"}} />
      </Badge>
      <span style={{marginLeft: "5px", color: "var(--BRAND-COLOR-TEXT)"}}>{Numbers.formatNumber(availableCredits, {})} Credits</span>
    </>;
  }

  function renderTargetEl() {
    return <DPButton
      className="dp-credits-header-info-btn"
      variant="text"
      label={renderTargetElLabel()} />;
  }

  function renderTooltipContentHeader() {
    return <>
      <Grid item xs={12}>
        <Typography
          sx={{color: "var(--BRAND-COLOR-TEXT)"}}
          m={0}
          p={0}
          variant="h6">Balance: {Numbers.formatNumber(availableCredits, {})} Credits</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link
          target="_blank"
          underline="none"
          href="https://docs.drivepoint.io/help/using-the-web-app/credits">Learn More</Link>
      </Grid>
    </>;
  }

  function renderTooltipContentProgressInfo() {
    const visualSettings = settingsBasedOnCreditsLeft(availableCredits);
    return <>
      <Grid item xs={12} mt={2} mb={2}>
        <LinearProgress
          sx={{
            background: visualSettings.backgroundWithOpacity,
            ".MuiLinearProgress-bar": {background: visualSettings.background}
          }}
          value={creditsLeftInPercent > 100 ? 100 : creditsLeftInPercent}
          variant="determinate" />
      </Grid>
    </>;
  }

  function renderAlertMessage(creditsLeft: number) {
    if (creditsLeft >= 600) { return; }
    const visualSettings = settingsBasedOnCreditsLeft(availableCredits);

    return <Grid item xs={12} mb={2}>
      <DPAlert severity="warning" sx={{
        background: visualSettings.backgroundWithOpacity,
        ".MuiAlert-icon": {color: visualSettings.background}
      }}>
        <Typography variant="body2" sx={{color: visualSettings.background}}>{visualSettings?.msg}</Typography>
      </DPAlert>
    </Grid>;
  }

  function renderAddCreditsAction() {
    return <>
      <Grid item xs={6}>
        <DPButton onClick={() => {
          tooltipRef.current?.resetAnchor();
          showModal(DPBuyCreditsDialog);
        }} fullWidth variant="outlined" label="BUY CREDITS" sx={{color: "var(--BRAND-COLOR-PRIMARY)!important"}} />
      </Grid>
      <Grid item xs={6}>
        <DPButton onClick={() => {
          tooltipRef.current?.resetAnchor();
          showModal(DPRedeemCreditsDialog);
        }} variant="contained" label="REDEEM CREDITS" fullWidth />
      </Grid>
    </>;
  }

  function renderTooltipContent() {
    return <div className="dp-credits-header-info-tooltip-content">
      <Grid container spacing={1}>
        {renderTooltipContentHeader()}
        {renderTooltipContentProgressInfo()}
        {renderAlertMessage(availableCredits)}
        {renderAddCreditsAction()}
      </Grid>
    </div>;
  }

  if (isLoading) {
    return <>
      <Skeleton variant="circular" width={30} height={30} sx={{marginRight: "5px"}} />
      <Skeleton variant="rectangular" width={100} height={10} />
    </>;
  }

  return <DPNewTooltip
    cardCSS={{background: "var(--BRAND-BACKGROUND-COLOR)"}}
    ref={tooltipRef}
    triggerEl={renderTargetEl()}
    eventType="hover">
    {renderTooltipContent()}
  </DPNewTooltip>;
}
