import React from "react";
import {Button, DialogProps, Grid} from "@mui/material";
import {closeSnackbar, SnackbarKey} from "notistack";
import {FieldValues, useForm} from "react-hook-form";
import * as Yup from "yup";
import {useStore} from "@bainbridge-growth/node-frontend";
import DPDialog from "@components/DPDialog/DPDialog";
import DPTextField from "@components/DPFields/DPTextField/DPTextField";
import {yupResolver} from "@hookform/resolvers/yup";
import CreditsStore from "@services/store/CreditsStore";
import Toast from "@services/Toast";

type DialogDPRedeemCreditsDialogProps = Pick<DialogProps, "open" | "onClose">;

export default function DPRedeemCreditsDialog({open, onClose}: DialogDPRedeemCreditsDialogProps) {

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    notes: Yup.string().required().max(300)
  });

  const {register, formState: {errors}, handleSubmit} = useForm({defaultValues: {
    title: "",
    notes: ""
  }, resolver: yupResolver(validationSchema)});

  const [_, creditsStore] = useStore<any, CreditsStore>(CreditsStore);

  function handleClose() {
    if (onClose) { onClose({}, "backdropClick"); }
  }

  async function handleSendRequest(data: FieldValues) {
    handleClose();
    let key: SnackbarKey | undefined;
    try {
      key = Toast.progress("Sending request to Drivepoint team");
      await creditsStore.requestRedeemCredits(data);
      Toast.success("Our team received your request. We will get back to you within two business days.");
    } catch (error) {
      Toast.error(error, {persist: true});
    } finally {
      closeSnackbar(key);
    }
  }

  function renderContent() {
    return <>
      <Grid item xs={12}>
        <DPTextField
          error={!!errors.title?.message}
          helperText={errors?.title?.message}
          label="Summary Title"
          fullWidth
          {...register("title", {required: true})} />
      </Grid>
      <Grid item xs={12}>
        <DPTextField
          error={!!errors.notes?.message}
          helperText={errors?.notes?.message}
          label="Notes"
          {...register("notes", {required: true})}
          fullWidth
          multiline
          rows={4}
          placeholder="Examples include Scenario Planning on New Product or Channel Launches, Custom Investor Performance Write-Up, Payback Calculations, Advanced Benchmarking reports, Scenario Planning on New Product/Channel Launches, and much more." />
      </Grid>
    </>;
  }

  function renderRequestActionSection() {
    return  <Grid item xs={12} textAlign="right">
      <Button variant="contained" type="submit">REQUEST TO REDEEM CREDITS</Button>
    </Grid>;
  }

  return <DPDialog title="Request Drivepoint Services" hideHeaderDivider hideFooterDivider isOpen={open} handleClose={handleClose}>
    <form onSubmit={handleSubmit(handleSendRequest)}>
      <Grid container spacing={2} className="buy-credits-dialog" pt={2}>
        {renderContent()}
        {renderRequestActionSection()}
      </Grid>
    </form>
  </DPDialog>;
}
