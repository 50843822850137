/**
 * Copyright ©2025 Drivepoint
 */

type MutexReleaseFunction = () => void;

export default class KeyedMutex {

  private readonly _locks: any = {};

  constructor() {
    this._locks = {};
  }

  async acquire(key: string): Promise<MutexReleaseFunction> {
    if (!this._locks[key]) { this._locks[key] = Promise.resolve(); }
    let release: any;
    const next = new Promise((resolve) => { release = resolve; });
    const current = this._locks[key];
    this._locks[key] = current.then(() => next);
    await current;
    return (): void => {
      release();
      if (this._locks[key].PromiseStatus === "resolved") { delete this._locks[key]; }
    };
  }

  async runExclusive(key: string, callback: () => any): Promise<any> {
    const release = await this.acquire(key);
    try {
      return await callback();
    } finally {
      release();
    }
  }
}
