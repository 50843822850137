/**
 * Copyright ©2022 Drivepoint
 */

import React, {useRef, useState} from "react";
import {AccountCircle, Brightness4, Brightness7, Check, Info, Link, Login, Logout, Notifications} from "@mui/icons-material";
import {Avatar, Box, IconButton, ListItem, ListItemText, MenuItem} from "@mui/material";
import {useModal} from "mui-modal-provider";
import {useNavigate} from "react-router-dom";
import {English} from "@bainbridge-growth/node-common";
import {useStore} from "@bainbridge-growth/node-frontend";
import AboutDialog, {AboutDialogInterface} from "@components/AboutDialog/AboutDialog";
import DPSubMenu from "@components/DPSubMenu/DPSubMenu";
import usePage from "@hooks/usePage";
import useStateChange from "@hooks/useStateChange";
import useThemeContext from "@hooks/useThemeContext";
import Firebase from "@services/firebase/Firebase";
import ServiceRegistry from "@services/ServiceRegistry";
import ExcelCompanyStore from "@services/store/ExcelCompanyStore";
import Telemetry from "@services/telemetry/Telemetry";
import DrivepointUser from "@services/user/DrivepointUser";
import BrandedDivider from "../../styled/BrandedDivider";
import BrandedMenu from "../../styled/BrandedMenu";
import PrimaryIcon from "../../styled/PrimaryIcon";
import ThemedMenuItem from "../../styled/ThemedMenuItem";
import LinkAccountDialog, {LinkAccountDialogProps} from "./LinkAccountDialog/LinkAccountDialog";

export default function AvatarButton(): any {

  const navigate = useNavigate();
  const page = usePage();
  const {ThemeContext} = useThemeContext();
  const company = useStateChange<any>("company");
  const flags = useStateChange<any>("flags");
  const [excelCompany] = useStore<any, ExcelCompanyStore>(ExcelCompanyStore);
  const user = useStateChange<DrivepointUser>("user");
  const {showModal} = useModal();

  const aboutDialog = useRef<AboutDialogInterface>(null);
  const linker = useRef<any>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [advanced, setAdvanced] = useState<boolean>();

  function getUserFullName(): string {
    if (user?.excelUser) {
      return `${user?.excelUser.firstName} ${user?.excelUser.lastName}`;
    } else {
      return "";
    }
  }

  function getUserAvatar(): any {
    let avatar;
    const fullName = getUserFullName();
    if (user?.user?.photoURL) {
      avatar = <Avatar alt={fullName} aria-label={fullName} src={user?.user?.photoURL} sx={{width: 32, height: 32}} />;
    } else {
      avatar = <AccountCircle />;
    }
    return avatar;
  }

  function show(event: any) {
    setAdvanced(event.altKey);
    setAnchorEl(event.currentTarget);
  }

  function hide() {
    setAnchorEl(null);
  }

  function showAbout() {
    hide();
    aboutDialog.current?.show();
  }

  function navigateToNotificationSettings() {
    navigate(`${company.id}/settings/notification_settings`);
  }

  function signIn() {
    hide();
    navigate("/sign_in");
  }

  function signOut() {
    hide();
    Firebase.signOut().then(() => navigate("/"));
  }

  async function linkAccounts(): Promise<void> {
    hide();
    if (!user?.user) { return; }
    showModal<LinkAccountDialogProps>(LinkAccountDialog, {});
  }

  function action(item: any, themeContext?: any) {
    Telemetry.track(`avatar_item_${item}`);
    switch (item) {
      case "theme":
        themeContext.toggle();
        hide();
        break;
      case "notification_settings":
        return navigateToNotificationSettings();
      case "about":
        return showAbout();
      case "sign_in":
        return signIn();
      case "sign_out":
        return signOut();
      case "link-accounts":
        return linkAccounts();
    }
  }

  function fullName(): any {
    if (user?.excelUser) {
      return `${user.excelUser.firstName} ${user.excelUser.lastName}`;
    }
  }

  function renderAccountItem(): any {
    if (user) {
      return <div>
        <ListItem>
          <Box sx={{width: "100%", margin: "2rem 0"}}>
            <Box sx={{textAlign: "center", margin: "0.25rem", fontSize: "1.5rem"}}>{fullName()}</Box>
            <Box sx={{textAlign: "center", margin: "0.25rem", fontSize: "0.75rem"}}>{user.user.email}</Box>
            <Box sx={{textAlign: "center", margin: "0.25rem"}}>{excelCompany?.name}</Box>
          </Box>
        </ListItem>
        <BrandedDivider sx={{margin: "8px 0"}} />
      </div>;
    }
  }

  function renderSignInOrSignOutItem(): any {
    if (user) {
      return <ThemedMenuItem onClick={() => action("sign_out")}>
        <PrimaryIcon><Logout /></PrimaryIcon>
        <ListItemText>Sign Out</ListItemText>
      </ThemedMenuItem>;
    } else {
      return <ThemedMenuItem onClick={() => action("sign_in")}>
        <PrimaryIcon><Login /></PrimaryIcon>
        <ListItemText>Sign In</ListItemText>
      </ThemedMenuItem>;
    }
  }

  function renderToggleThemeItem(themeContext: any): any {
    return <ThemedMenuItem onClick={() => action("theme", themeContext)}>
      <PrimaryIcon>{themeContext.mode === "dark" ? <Brightness7 /> : <Brightness4 />}</PrimaryIcon>
      <ListItemText>Toggle light/dark theme</ListItemText>
    </ThemedMenuItem>;
  }

  function renderNotificationSettingsItem(): any {
    return <MenuItem onClick={() => action("notification_settings")}>
      <PrimaryIcon><Notifications /></PrimaryIcon>
      <ListItemText>Notification Center</ListItemText>
    </MenuItem>;
  }

  function renderAboutItem(): any {
    return <ThemedMenuItem onClick={() => action("about")}>
      <PrimaryIcon><Info /></PrimaryIcon>
      <ListItemText>About...</ListItemText>
    </ThemedMenuItem>;
  }

  function renderLinkAccountItem(): any {
    if (!advanced) { return; }
    if (!Array.isArray(user?.user?.providerData)) { return; }
    const providers = user.user.providerData.map(it => it.providerId);
    const disabled = providers.includes("password"); // "password" provider is Email provider
    return <div>
      <BrandedDivider style={{margin: "8px 0"}} />
      <ThemedMenuItem disabled={disabled} onClick={() => action("link-accounts")}>
        <PrimaryIcon><Link /></PrimaryIcon>
        <ListItemText>Link Email Account...</ListItemText>
      </ThemedMenuItem>
    </div>;
  }

  function switchCompany(id: string): void {
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes("drivepoint_plan")) {
        sessionStorage.removeItem(key);
      }
    });
    hide();
    const defaultPage = page.current || ServiceRegistry.routeService.defaultPage;
    let path = defaultPage.path;
    const matches = Array.from(window.location.pathname.match(defaultPage.pattern) || []);
    const tokens = ServiceRegistry.routeService.getPathTokens(path);
    for (const [index, token] of tokens.entries()) {
      switch (token) {
        case ":company":
          path = path.replace(token, id);
          break;
        case ":bundle_id":
          path = path.replace(token, matches[index + 1]);
          break;
        case ":id":
          path = path.replace(token, matches[index + 1]);
          break;
      }
    }
    ServiceRegistry.companyService.setCompanyById(id);
    window.location.assign(path);
  }

  function renderCompanies(): any {
    if (!user) { return; }
    if (!Array.isArray(user?.companies)) { return; }
    if (user.companies.length < 2) { return; }
    const items = user.companies
      .sort((a: any, b: any) => English.baseProperNoun(a.name).localeCompare(English.baseProperNoun(b.name)))
      .map((it: any, index: number) => {
        const selected = it.id === company?.id;
        return <ThemedMenuItem key={`company_${it.id}_${index}`} id={it.name} className={selected ? "selected" : ""} onClick={() => switchCompany(it.id)}>
          <PrimaryIcon style={{opacity: selected ? "1.0" : "0"}}><Check /></PrimaryIcon>
          <ListItemText>{it.name}</ListItemText>
        </ThemedMenuItem>;
      });
    return <DPSubMenu label="Companies" onClose={hide} open={!!anchorEl}>
      {items}
    </DPSubMenu>;
  }

  function renderMenu(themeContext: any): any {
    return <BrandedMenu open={!!anchorEl} onClose={hide} anchorEl={anchorEl}>
      {renderAccountItem()}
      {renderCompanies()}
      {renderToggleThemeItem(themeContext)}
      {flags["page.settings.notification_settings.enabled"] && <BrandedDivider />}
      {flags["page.settings.notification_settings.enabled"] && renderNotificationSettingsItem()}
      <BrandedDivider />
      {renderAboutItem()}
      {renderLinkAccountItem()}
      <BrandedDivider style={{margin: "8px 0"}} />
      {renderSignInOrSignOutItem()}
    </BrandedMenu>;
  }

  return <ThemeContext.Consumer>
    {(themeContext: any) => {
      return <div className="avatar-button">
        <div ref={linker} className="avatar-button-account-linker" />
        <IconButton onClick={show} size="large" color="inherit">
          {getUserAvatar()}
        </IconButton>
        {renderMenu(themeContext)}
        <AboutDialog ref={aboutDialog} />
      </div>;
    }}
  </ThemeContext.Consumer>;

}
