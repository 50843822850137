/**
 * Copyright ©2022 Drivepoint
 */

import * as React from "react";
import AvatarButton from "../AvatarButton/AvatarButton";
import UserNameAndCompany from "../UserNameAndCompany/UserNameAndCompany";
import "./UserBox.css";

export default function UserBox(props: any): any {

  return <div className="user-box">
    <UserNameAndCompany />
    <AvatarButton />
  </div>;
};
