/**
 * Copyright ©2025 Drivepoint
 */

import {Store} from "@bainbridge-growth/node-frontend";
import WebAppServerClient from "../clients/WebAppServerClient";
import State from "../state/State";
import {StateEvent} from "../state/StateEvent";

export default class CreditsStore extends Store {

  private _company?: any;
  private _abortController: AbortController = new AbortController();

  init(): void {
    this.loading = true;
    State.register("company", (event: StateEvent) => {
      this.value = {};
      this._company = event.value;
      this.refresh();
    });
  }

  async refresh(): Promise<void> {
    if (!this._company) { return; }
    return this.action(async () => {
      this._abortController?.abort();
      this._abortController = new AbortController();
      this.value = await WebAppServerClient.request(`/ui/company/${this._company?.id}/available_credits`, "GET", undefined, this._abortController);
    });
  }

  async requestCredits(purchaseValue: string, notes: string): Promise<void> {
    if (!this._company) { return; }
    await WebAppServerClient.request(`/ui/company/${this._company?.id}/purchase_credits`, "POST", {purchaseValue, notes});
  }

  async requestRedeemCredits(data: Record<string, any>): Promise<void> {
    if (!this._company) { return; }
    await WebAppServerClient.request(`/ui/company/${this._company?.id}/redeem_credits`, "POST", {...data});
  }

}
