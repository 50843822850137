/**
 * Copyright ©2022 Drivepoint
 */

import md5 from "md5";
import {ObjectUtilities} from "@bainbridge-growth/node-common";
import FavoritesService from "@services/route/FavoritesService";
import staticRoutes from "../../../config/routes.json";
import ComponentRegistry from "../ComponentRegistry";
import ServiceRegistry from "../ServiceRegistry";
import State from "../state/State";

const TEMP_ROUTE: any = {
  id: "temp_route",
  pattern: /\/(.+?)\/page\/(.+?)/,
  tokens: [":company"],
  visibility: "company",
  hidden: true
};

export enum RouteServicePageVisibility {
  GLOBAL = "global",
  COMPANY = "company",
  USER = "user"
}

export default class RouteService extends EventTarget {

  routingConfiguration: any = this.hydrateItems();
  pages: any[] = [];
  sections: any[] = [];

  async init(): Promise<void> {
    await this.rehydrate();
    State.registerMany("user", "company", "flags", this.rehydrate.bind(this));
  }

  async rehydrate(): Promise<void> {
    this.routingConfiguration = this.hydrateItems();
    this.pages = await this.hydratePages();
    this.sections = await this.hydrateSections();
    State.set("routes", {pages: this.pages, sections: this.sections});
  }

  addItem(item: any, routes: any, section?: any): void {
    if (item.children) {
      const section: any = {...item, children: []};
      routes.sections.push(section);
      for (const child of item.children) {
        this.addItem(child, routes, section);
      }
    } else {
      section.children.push({id: item.id, type: "page"});
      routes.pages.push(item);
    }
  }

  hydrateItems(): any {
    const company = State.get("company");
    const excelUser = State.get("user")?.excelUser;
    const items: any[] = [];
    this.mergePages(RouteServicePageVisibility.GLOBAL, items, staticRoutes);
    this.mergePages(RouteServicePageVisibility.COMPANY, items, company?.pages);
    this.mergePages(RouteServicePageVisibility.USER, items, excelUser?.pages);
    const routes: any = {
      pages: [],
      sections: [{id: "PRIMARY", children: []}, {id: "SECONDARY", children: []}]
    };
    for (const item of items) {
      const sectionIndex = item.placement === "bottom" ? 1 : 0;
      this.addItem(item, routes, routes.sections[sectionIndex]);
    }
    return routes;
  }

  mergePages(visibility: string, destination: any[], source: any[] = []): any {
    if (Array.isArray(source)) {
      ObjectUtilities.clone(source)
        .map((page: any) => ({...page, visibility: visibility}))
        .forEach((page: any) => destination.push(page));
    }
  }

  makePageId(page: any): string {
    const values = [page.name, page.component, page.visibility];
    if (page.url) { values.push(page.url); }
    if (page.urlKey) { values.push(page.urlKey); }
    return md5(`${values.join(".")}`);
  }

  listPageIdToSection(listPageId: string): string {
    switch (listPageId) {
      case "company.reports": return "company.analytics";
      case "company.scorecards": return "company.finance";
      default: return listPageId;
    }
  }

  sectionToListPageId(section: string): string {
    switch (section) {
      case "company.analytics": return "company.reports";
      case "company.dashboards": return "company.scorecards";
      case "company.finance": return "company.scorecards";
      default: return section;
    }
  }

  updateCustomPageForNavigationList(page: any, excelCompany?: any): void {
    page.custom = true;
    page.hidden = true;
    page.listPageId = this.sectionToListPageId(page.section);
    page.parent = page.listPageId;
    page.category = "Custom";
    page.created_by = excelCompany?.name;
  }

  async hydratePages(): Promise<any[]> {
    const company = State.get("company");
    const excelUser = State.get("user")?.excelUser;
    let pages: any[] = ObjectUtilities.clone(this.routingConfiguration?.pages);
    const favorites = await FavoritesService.get(company?.id, excelUser?.email);
    pages.forEach((page: any) => {
      if (!page.id) { page.id = this.makePageId(page); }
      if (!page.path) { page.path = `/:company/page/${page.id}`; }
      if (page.visibility === RouteServicePageVisibility.COMPANY) { this.updateCustomPageForNavigationList(page, company); }
      this.hydrateComponents(page);
      const tokens = page.path.match(new RegExp(/(:\w+)/g));
      if (tokens) {
        let regexp = `^${page.path}$`;
        for (const token of tokens) {
          regexp = regexp.replace(new RegExp(token, "g"), "(.+?)");
        }
        page.tokens = tokens;
        page.pattern = new RegExp(regexp);
      }
      page.favorite = favorites.includes(page.id);
      if (page.favorite) { page.hidden = false; }
    });
    if (!company) { pages = [...pages, TEMP_ROUTE]; }
    return pages;
  }

  hydrateComponents(page: any): void {
    try {
      if (page.component) { page.component = ComponentRegistry.classForName(page.component); }
      if (page.infoComponent) { page.infoComponent = ComponentRegistry.classForName(page.infoComponent); }
      if (page.icon) { page.icon = ComponentRegistry.classForName(page.icon); }
    } catch (error: any) {
      logger.debug(error.message);
    }
  }

  async hydrateSections(): Promise<any[]> {
    const excelUser = State.get("user")?.excelUser;
    const company = State.get("company");
    const userFavorites = await FavoritesService.get(company?.id, excelUser?.email);
    let sections: any[] = Array.isArray(this.routingConfiguration?.sections) ? ObjectUtilities.clone(this.routingConfiguration?.sections) : [];
    sections = sections
      .map((section: any) => {
        section.pages = section.children
          .map((child: any) => {
            if (child.type === "page") {
              return this.pages.find((page: any) => page.id === child.id);
            }
          })
          .filter((it: any) => it);
        if (section.icon) { section.icon = ComponentRegistry.classForName(section.icon); }
        delete section.children;
        return section;
      });
    let favorites = sections.reduce((favorites: any[], section: any) => [
      ...favorites,
      ...section.pages.filter((page: any) => page.favorite)
    ], []);
    favorites = [
      ...favorites,
      ...(userFavorites ?? [])
        .filter((fav: any) => fav?.type === "dazzler" && fav?.company === company?.id)
        .map((el: any) => ({...el, favorite: true}))
    ];
    const section = sections.find(section => section.id === "favorites");
    if (section) {
      for (const favorite of favorites) {
        sections.forEach(section => section.pages = section.pages.filter((page: any) => page.id !== favorite.id));
        section.pages.push(favorite);
      }
    }
    return sections;
  }

  get defaultPage(): any {
    return this.pages.find((page: any) => page.default);
  }

  getPageDefinitionById(id: string): any {
    return this.routingConfiguration.pages.find((page: any) => page.id === id);
  }

  getPageById(id: string): any {
    return this.pages.find((page: any) => page.id === id);
  }

  getPageByPathname(pathname: string): any {
    return this.pages.find((page: any) => page.path === pathname || (page.pattern && pathname.match(page.pattern)));
  }

  getCurrentPageParams(): any {
    const page = this.getPageByPathname(location.pathname);
    if (page?.pattern) {
      const match = location.pathname.match(page.pattern);
      if (match) {
        return page.tokens.reduce((values: any, token: string, index: number) => ({...values, [token]: match[index + 1]}), {});
      }
    }
    return {};
  }

  getTokenValue(token: string, company: any): string {
    switch (token) {
      case ":company": return company?.id || "-";
      default: return token;
    }
  };

  getPathForContext(path: string, company: any): string {
    const tokens = ServiceRegistry.routeService.getPathTokens(path);
    if (tokens) {
      for (const token of tokens) {
        path = path.replace(token, this.getTokenValue(token, company));
      }
    }
    return path;
  };

  getPathTokens(path: string): any[] {
    const tokens = path.match(new RegExp(/(:\w+)/g));
    return tokens || [];
  };

}
