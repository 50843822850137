/**
 * Copyright ©2023 Drivepoint
 */

export type NumberFormattingOptions = {
  fractionDigits?: number;
  locale?: string;
  currency?: string;
  emptyValue?: string;
  notation?: "standard" | "scientific" | "engineering" | "compact";
  useGrouping?: boolean;
  signDisplay?: "auto" | "always" | "exceptZero" | "never";
  currencySign?: "accounting"
};

export default class Numbers {

  private static DEFAULT_OPTIONS: NumberFormattingOptions = {
    fractionDigits: 0,
    locale: "en-US",
    currency: "USD",
    emptyValue: "",
    useGrouping: true,
    signDisplay: "auto"
  };

  static format(value: any, type: string | undefined, options: NumberFormattingOptions = {}): string {
    options = Object.assign({}, Numbers.DEFAULT_OPTIONS, options);
    if (value === undefined || value === null) { return options.emptyValue || ""; }
    if (typeof value === "string") { value = parseFloat(value); }
    if (typeof value !== "number") { return options.emptyValue || ""; }
    switch (type) {
      case "number": return Numbers.formatNumber(value, options);
      case "currency": return Numbers.formatCurrency(value, options);
      case "percent": return Numbers.formatPercent(value, options);
      default: return `${value}`;
    }
  }

  static formatNumber(value: number, options: NumberFormattingOptions): string {
    return value.toLocaleString(
      options.locale,
      {
        minimumFractionDigits: options.fractionDigits,
        maximumFractionDigits: options.fractionDigits,
        useGrouping: options.useGrouping,
        signDisplay: options.signDisplay
      }
    );
  }

  static formatCurrency(value: number, options: NumberFormattingOptions): string {
    return value.toLocaleString(
      options.locale,
      {
        style: "currency",
        minimumFractionDigits: options.fractionDigits,
        maximumFractionDigits: options.fractionDigits,
        currency: options.currency,
        notation: options.notation,
        signDisplay: options.signDisplay,
        useGrouping: options.useGrouping,
        currencySign: options.currencySign
      }
    );
  }

  static formatPercent(value: number, options: NumberFormattingOptions): string {
    return value.toLocaleString(
      options.locale,
      {
        style: "percent",
        minimumFractionDigits: options.fractionDigits,
        maximumFractionDigits: options.fractionDigits,
        useGrouping: options.useGrouping,
        signDisplay: options.signDisplay
      }
    );
  }

  static toNumber(value: unknown): number | undefined {
    if (value === "undefined" || value == null) { return undefined; }
    if (typeof value === "string") { value = parseFloat(value); }
    if (Number.isNaN(value)) { value = undefined; }
    if (typeof value !== "number") { return undefined; }
    return value;
  }

}
