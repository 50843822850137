/**
 * Copyright ©2023 Drivepoint
 */

import React, {ReactNode} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import DPDivider from "../DPDivider/DPDivider";
import "./DPDialog.less";

type DPDialogProps = {
  isOpen: boolean;
  hideHeaderDivider?: boolean;
  hideFooterDivider?: boolean;
  hideCloseButton?: boolean;
  title?: string | ReactNode;
  handleClose?: () => void;
  actions?: ReactNode;
  children?: ReactNode;
  className?: string
};

export default function DPDialog(
  {
    isOpen,
    title,
    children,
    actions,
    handleClose,
    hideHeaderDivider = false,
    hideFooterDivider = false,
    hideCloseButton,
    className
  }: DPDialogProps) {

  return <Dialog open={isOpen} className={`dp-dialog-container ${className ?? ""}`} onClose={handleClose}>
    {(!hideCloseButton || title) && <DialogTitle className="dp-dialog-header">
      <div className="dp-dialog-header-title">{title}</div>
      <div className="dp-dialog-header-toolbar">
        {!hideCloseButton && <IconButton onClick={handleClose} sx={{color: "rgba(51, 51, 51, 0.56)"}}>
          <CloseIcon />
        </IconButton>}
      </div>
    </DialogTitle>}
    {!hideHeaderDivider && <DPDivider type="horizontal" />}
    <DialogContent>{children}</DialogContent>
    {actions && !hideFooterDivider && <DPDivider type="horizontal" />}
    {actions && <DialogActions className="dp-dialog-actions">{actions}</DialogActions>}
  </Dialog>;
}
