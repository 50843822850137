/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";
import type {TextFieldProps} from "@mui/material";
import {TextField} from "@mui/material";
import "./DPTextField.css";

export type DPTextFieldProps = Omit<TextFieldProps, "variant">;

const DPTextField = React.forwardRef<HTMLInputElement, DPTextFieldProps>(
  ({className, ...props}, ref) => {
    return (
      <TextField
        className={`dp-text-field ${className}`}
        variant="outlined"
        {...props}
        inputRef={ref}
      />
    );
  }
);

export default DPTextField;
