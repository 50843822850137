import type {FormulaOptions, MarginFormulasKeys} from "@utilities/FormulasMap";
import {renderFormula} from "@utilities/FormulasMap";

type FormulaType  = keyof FormulaOptions;

type MarginProps = [type: MarginFormulasKeys, formulaType: FormulaType, formulaValues: Record<string, number>];

export default class Margins {

  /**
   * A static method that calculates the value of a specific formula by delegating to the `renderFormula` function.
   *
   * See the `renderFormula` function for detailed documentation on how formulas are processed.
   *
   * @param props - A spread of arguments matching the parameters of the `renderFormula` function:
   *   1. `type` (keyof typeof FormulasMap): The key of the formula category (e.g., "gross_margin", "net_income_margin").
   *   2. `formulaType` (keyof FormulaOptions): The specific formula to use ("formula_latest" or "formula_previous").
   *   3. `formulaValues` (Record<string, number>): An object containing key-value pairs where keys match placeholders
   *      in the formula and values are the corresponding numbers to substitute.
   *
   * @returns The calculated value of the formula as a number, or `null` if an error occurs during the calculation or
   *          if required placeholders are missing in the provided values.
   *
   * @example
   * // Example usage:
   * const value = SomeClass.getValueFor(
   *   "gross_margin",
   *   "formula_latest",
   *   { quickbooks_total_income_latest: 5000, quickbooks_total_cogs_latest: 2000 }
   * );
   * console.log(value); // 0.6
   */
  static getValueFor(...props: MarginProps): number | null {
    return renderFormula(...props);
  }
}
