/**
 * Copyright ©2025 Drivepoint
 */

import {Mutex} from "async-mutex";
import WebAppServerClient from "@services/clients/WebAppServerClient";

type FavoritesEntry = {
  companyId?: string;
  email?: string;
  favorites: any[];
  timestamp: number;
};

export default class FavoritesService {

  private static _favorites: FavoritesEntry = {favorites: [], timestamp: 0};
  private static _mutex: any = new Mutex();

  static async get(companyId: string | null, email: string | null): Promise<(Record<string, any> | string)[]> {
    if (!companyId || !email) { return []; }
    FavoritesService._age(companyId, email);
    if (FavoritesService._exists(companyId, email)) { return FavoritesService._favorites.favorites; }
    const favorites = await FavoritesService._mutex.runExclusive(async () => {
      if (FavoritesService._exists(companyId, email)) { return FavoritesService._favorites.favorites; }
      const response = await WebAppServerClient.request(`/api/company/${companyId}/user/${email}/favorites`, "GET");
      FavoritesService._favorites = {favorites: response ?? [], companyId: companyId, email: email, timestamp: Date.now()};
      return FavoritesService._favorites.favorites;
    });
    return favorites ?? [];
  }

  private static _age(companyId: string | null, email: string | null): void {
    if (FavoritesService._favorites.timestamp) {
      // check for age, clear if too old
    }
    if (FavoritesService._favorites.companyId !== companyId || FavoritesService._favorites.email !== email) { FavoritesService.clear(); }
  }

  private static _exists(companyId: string | null, email: string | null): boolean {
    return FavoritesService._favorites.companyId === companyId && FavoritesService._favorites.email === email;
  }

  static clear(): void {
    FavoritesService._favorites = {favorites: [], timestamp: 0};
  }

}
