import React, {useState} from "react";
import {Button, Chip, DialogProps, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, Typography} from "@mui/material";
import {closeSnackbar, SnackbarKey} from "notistack";
import {useStore} from "@bainbridge-growth/node-frontend";
import DPDialog from "@components/DPDialog/DPDialog";
import DPTextField from "@components/DPFields/DPTextField/DPTextField";
import CreditsStore from "@services/store/CreditsStore";
import Toast from "@services/Toast";
import "./DPBuyCreditsDialog.css";

type DialogDPBuyCreditsDialogProps = {

}& Pick<DialogProps, "open" | "onClose">;

export default function DPBuyCreditsDialog({open, onClose}: DialogDPBuyCreditsDialogProps) {

  const optionItems = [
    {label: "300 credits", cost: "$1,200", value: "300"},
    {label: "1200 credits", cost: "$4,400", value: "1200"},
    {label: "3600 credits", cost: "$12,000", value: "3600"}
  ];

  const [_, creditsStore] = useStore<any, CreditsStore>(CreditsStore);
  const [selectedCredits, setSelectedCredits] = useState<string>("300");
  const [notes, setNotes] = useState("Looking to purchase more credits for Drivepoint services and products.");

  function handleClose() {
    if (onClose) { onClose({}, "backdropClick"); }
  }

  function handleSelection(value: string) {
    setSelectedCredits(value);
  }

  async function handleSendRequest() {
    handleClose();
    let key: SnackbarKey | undefined;
    try {
      key = Toast.progress("Sending request to Drivepoint team");
      await creditsStore.requestCredits(selectedCredits, notes);
      Toast.success("Our team received your request. We will get back to you within two business days.");
    } catch (error) {
      Toast.error(error, {persist: true});
    } finally {
      closeSnackbar(key);
    }
  }

  function renderListOfOptions() {
    return <Grid item xs={12}>
      <Typography variant="overline" color="var(--GREY-500)">One-Time Purchase</Typography>
      <List className="dp-purchase-credits-list">
        {optionItems.map((item, index) =>
          <ListItem
            key={index}
            onClick={() => { handleSelection(item.value); }}
            secondaryAction={<span style={{color: "var(--GREY-500)"}}>{item.cost}</span>}
            className={`dp-purchase-credits-list-item ${item.value === selectedCredits ? "selected" : ""}`}>
            <ListItemButton sx={{maxWidth: "40px"}} dense>
              <ListItemIcon>
                <Radio edge="start" checked={item.value === selectedCredits} />
              </ListItemIcon>
            </ListItemButton>
            <ListItemText>{item.label}</ListItemText>
          </ListItem>)}
      </List>
    </Grid>;
  }

  function renderSubscriptionOption() {
    const isChecked = selectedCredits === "monthly";
    return <Grid item xs={12}>
      <Typography variant="overline" color="var(--GREY-500)">Subscription</Typography>
      <List className="dp-purchase-credits-list">
        <ListItem
          sx={{height: "68px!important"}}
          onClick={() => { handleSelection("monthly"); }}
          secondaryAction={<Chip color="success" label="Up to 20% Discount" />}
          className={`dp-purchase-credits-list-item ${isChecked ? "selected" : ""}`}>
          <ListItemButton sx={{maxWidth: "40px"}} dense>
            <ListItemIcon>
              <Radio edge="start" checked={isChecked} />
            </ListItemIcon>
          </ListItemButton>
          <ListItemText
            primary="MonthlyCredit Subscription"
            secondary="Select to inquire about subscription packages" />
        </ListItem>
      </List>
    </Grid>;
  }

  function renderNotesSection() {
    return  <Grid item xs={12}>
      <DPTextField fullWidth multiline rows={4} label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
    </Grid>;
  }

  function renderRequestActionSection() {
    return  <Grid item xs={12} textAlign="right">
      <Button variant="contained" onClick={handleSendRequest}>SEND REQUEST</Button>
    </Grid>;
  }

  return <DPDialog title="Buy Drivepoint Credits" hideHeaderDivider hideFooterDivider isOpen={open} handleClose={handleClose}>
    <Grid container spacing={2} className="buy-credits-dialog">
      {renderListOfOptions()}
      {renderSubscriptionOption()}
      {renderNotesSection()}
      {renderRequestActionSection()}
    </Grid>
  </DPDialog>;
}
