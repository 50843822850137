export type FormulaOptions = {
  formula: string;
};

type MarginFormulasKeys = keyof typeof MarginFormulasMap;

const MarginFormulasMap = {
  ebitda_margin: {
    formula: "{{ebitda}} / {{net_revenue}}"
  },
  ebit_margin: {
    formula: "{{ebit}} / {{net_revenue}}"
  },
  gross_margin: {
    formula: "{{gross_profit}} / {{net_revenue}}"
  },
  net_revenue_margin: {
    formula: "{{net_revenue}} / {{gross_sales}}"
  },
  net_income_margin: {
    formula: "{{net_income}} / {{net_revenue}}"
  },
  contribution_margin: {
    formula: "{{contribution_profit}} / {{net_revenue}}"
  },
  cogs_margin: {
    formula: "{{cogs}} / {{net_revenue}}"
  },
  opex: {
    formula: "{{operating_expenses}} / {{net_revenue}}"
  },
  contribution_margin_after_marketing: {
    formula: "{{contribution_profit_after_marketing}} / {{net_revenue}}"
  }
};

const FormulasMap = {
  ...MarginFormulasMap
};

/**
 * Renders a formula by replacing placeholders in the formula template with corresponding values
 * from the provided formulaValues object, evaluates the formula, and returns the result.
 *
 * @param type - The key of the formula type from the `FormulasMap` (e.g., "total_sales_margin", "gross_margin").
 * @param formulaType - The specific formula property to render, either "formula_latest" or "formula_previous".
 * @param formulaValues - An object containing key-value pairs where keys match placeholders in the formula,
 *                        and values are the corresponding numbers to substitute.
 *
 * @returns The evaluated result of the formula as a number if successful, or `null` if an error occurs.
 *
 * @throws An error if any placeholder in the formula cannot be found in the `formulaValues` object.
 *
 * @example
 * const result = renderFormula("gross_margin", "formula_latest", {
 *   quickbooks_total_income_latest: 5000,
 *   quickbooks_total_cogs_latest: 2000,
 * });
 * console.log(result); // 0.6
 */
function renderFormula(type: keyof typeof FormulasMap, formulaType: keyof FormulaOptions, formulaValues: Record<string, number>) {
  const formula = FormulasMap[type]?.[formulaType];

  if (formula) {
    try {
      const parsedFormula = formula.replace(/{{(.*?)}}/g, (_, key) => {
        if (formulaValues[key] === undefined) { throw Error(`can not find ${key} in the given values for ${type}`); }
        return formulaValues[key].toString();
      });

      const finalResult = eval(parsedFormula);

      if (!isFinite(finalResult)) {
        console.warn(`The actual value for ${type} was ${finalResult}... returning 0 instead`);
        return 0;
      }

      return finalResult;

    } catch (err) {
      console.error(err);
      return null;
    }
  }

  return null;
}

export {renderFormula, MarginFormulasKeys};
