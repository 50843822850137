/**
 * Copyright ©2025 Drivepoint
 */

import React from "react";
import {Box} from "@mui/material";
import {useStore} from "@bainbridge-growth/node-frontend";
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs";
import DPCreditsInfoTooltip from "@components/layout/DPHeader/DPCreditsInfoTooltip/DPCreditsInfoTooltip";
import usePage from "@hooks/usePage";
import useStateChange from "@hooks/useStateChange";
import BreadcrumbManager from "@services/BreadcrumbManager";
import CreditsStore from "@services/store/CreditsStore";
import TopAppBar from "../../../main/TopAppBar";
import UserBox from "../../../widgets/component/UserBox/UserBox";
import VersionUpdatedButton from "../../../widgets/component/VersionUpdatedButton";

type DPHeaderProps = {
  navigationOpen?: boolean
};

export default function DPHeader(props: DPHeaderProps) {

  const page = usePage();
  const flags = useStateChange<any>("flags");
  const [creditsInfo, creditsStore] = useStore<Record<string, any>, CreditsStore>(CreditsStore);
  if (page.current?.hideChrome) { return; }

  return <TopAppBar open={props.navigationOpen}>
    <Breadcrumbs ref={BreadcrumbManager.ref} />
    <Box sx={{flexGrow: 1}} />
    {flags["header.credits.enabled"] && <DPCreditsInfoTooltip
      availableCredits={parseInt(creditsInfo?.["Balance Credits"] ?? "0")}
      isLoading={creditsStore.loading}
    />}
    <VersionUpdatedButton />
    <UserBox />
  </TopAppBar>;
}
